import React, { useRef, useState, useEffect } from "react";
import CountUp from "react-countup";

const Feature = () => {
  const [startCount, setStartCount] = useState(false);
  const featureRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 }
    );

    if (featureRef.current) {
      observer.observe(featureRef.current);
    }

    return () => {
      if (featureRef.current) {
        observer.unobserve(featureRef.current);
      }
    };
  }, []);

  return (
    <div ref={featureRef}>
      <div className="featured-section">
        <div className="auto-container">
          <div className="row clearfix">
            {/* Featured Block One */}
            <div className="feature-block-two col-lg-6 col-md-12 col-sm-12">
              <div className="inner-box">
                <div className="number">
                  {startCount ? <CountUp start={0} end={35} duration={3} /> : 0}
                  +
                </div>
                <h4>Worldwide Work Pair</h4>
                <div className="text">
                  To succeed, every software solution must be deeply integrated
                  into the existing tech environment.
                </div>
              </div>
            </div>

            {/* Featured Block Two */}
            <div className="feature-block-two col-lg-6 col-md-12 col-sm-12">
              <div className="inner-box-1">
                <div className="number">
                  {startCount ? (
                    <CountUp start={0} end={60} duration={4} />
                  ) : (
                    0
                  )}
                  +
                </div>
                <h4>Happy Clients</h4>
                <div className="text">
                  To succeed, every software solution must be deeply integrated
                  into the existing tech environment.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
