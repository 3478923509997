import React from "react";
import { Link } from "react-router-dom";
const Blog4 = () => {
  return (
    <div>
      <div>
        {/* <!--Page Title--> */}
        <section class="page-title">
          <div
            class="pattern-layer-one"
            // style={{
            //   backgroundImage:
            //     "url(../../assets/Images/background/pattern-16.png)",
            // }}
          ></div>
          <div class="auto-container">
            <h2>Latest News</h2>
            <ul class="page-breadcrumb">
              <li>
                <Link to="/">Home &gt;</Link>
              </li>
              <li>Mobile App Development</li>
            </ul>
          </div>
        </section>
        {/* <!--End Page Title--> */}

        {/* <!-- Sidebar Page Container --> */}
        <div class="sidebar-page-container">
          <div class="auto-container">
            <div class="row clearfix">
              {/* <!-- Content Side --> */}
              <div class="content-side col-lg-12 col-md-12 col-sm-12">
                <div class="news-detail">
                  <div class="inner-box">
                    <div class="upper-box">
                      <h3>Mobile App Development</h3>
                      {/* <ul class="post-meta">
                        <li>
                          <span class="icon">
                            <i class="ri-message-line"></i>
                          </span>
                          3 comments
                        </li>
                        <li>
                          <span class="icon">
                            <i class="ri-user-line"></i>
                          </span>
                          Admin
                        </li>
                      </ul> */}
                    </div>
                    <div class="image">
                      <img
                        src="../../assets/Images/resource/Blog4.png"
                        alt=""
                      />
                      {/* <div class="post-date">
                        22 <span>DEC</span>
                      </div> */}
                    </div>

                    <div class="lower-content">
                      {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the eaque
                        ipsa quae ab illo inventore veritatis et quasi
                        architecto beatae.
                      </p>
                      <p>
                        On the other hand, we denounce with righteous
                        indignation and dislike men who are so beguiled and
                        demoralized by the charms of pleasure of the moment, so
                        blinded by desire, that they cannot foresee the pain and
                        trouble that are bound to ensue; and equal blame belongs
                        to those who fail in their duty through weakness of
                        will, which is the same as saying through shrinking from
                        toil and pain. These cases are perfectly simple and easy
                        to distinguish.
                      </p>
                      <blockquote>
                        <div class="blockquote-inner">
                          Lorem ipsum dolor sit amet consectetr adipicing elit
                          mod tempor incididunt enim minim quis nostrud exer
                          tation ullamco laboris nisi aliquip.
                          <span class="quote-icon">
                            <i class="ri-double-quotes-r"></i>
                          </span>
                        </div>
                      </blockquote>
                      <p></p> */}
                      <h4>Unleashing Innovation with Mobile App Development</h4>
                      <p>
                        Mobile app development has become essential in today’s
                        connected world, offering businesses a way to engage
                        customers directly. At Vincosys, we provide mobile app
                        development services that bring your ideas to life with
                        intuitive designs and powerful features. Our IT company
                        specializes in creating custom mobile applications for
                        iOS and Android, ensuring that each app aligns with your
                        brand’s goals and provides a seamless user experience.
                      </p>
                      <h4>
                        Comprehensive Mobile Solutions from Concept to Launch
                      </h4>
                      {/* <p>
                        Best quality only happens when you care enough to do
                        your best.
                      </p> */}
                      <p>
                        As a software development company, we take a strategic
                        approach to mobile app development, focusing on
                        functionality, performance, and security.From concept to
                        deployment, our team handles every stage of development
                        with meticulous attention to detail.
                      </p>
                      <p>
                        We offer scalable mobile solutions that grow with your
                        business, enabling you to connect with customers
                        anytime, anywhere. Whether you need an e-commerce app, a
                        healthcare solution, or a productivity tool, we’re here
                        to help you succeed.
                      </p>
                      <div class="row clearfix">
                        <div class="column col-lg-6 col-md-6 col-sm-12">
                          <ul class="list-style-two">
                            <li>
                              Custom mobile app solutions for iOS and Android.
                            </li>
                            <li>
                              User-focused design for an intuitive experience.
                            </li>
                            <li>
                              High-performance apps optimized for speed and
                              reliability.
                            </li>
                          </ul>
                        </div>
                        <div class="column col-lg-6 col-md-6 col-sm-12">
                          <ul class="list-style-two">
                            <li>Secure, scalable architecture for growth.</li>
                            <li>
                              End-to-end development from concept to deployment.
                            </li>
                            <li>Ongoing support and maintenance.</li>
                          </ul>
                        </div>
                      </div>

                      {/* <!-- Post Share Options--> */}
                      {/* <div class="post-share-options">
                        <div class="post-share-inner clearfix">
                          <div class="pull-left tags">
                            <Link to="#">Business</Link>{" "}
                            <Link to="#">Solution</Link>
                            <Link to="#">IT-Services</Link>
                          </div>
                          <ul class="social-box pull-right">
                            <li class="share">Share This Post :</li>
                            <li>
                              <Link to="#">
                                <span class="fa fa-facebook-f"></span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <span class="fa fa-twitter"></span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <span class="fa fa-google-plus"></span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <span class="fa fa-pinterest-p"></span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  {/* <div class="comments-area">
                    <div class="group-title">
                      <h3>2 Comments</h3>
                    </div>

                    <div class="comment-box">
                      <div class="comment">
                        <div class="author-thumb">
                          <img
                            src="../../assets/Images/resource/author-1.jpg"
                            alt=""
                          />
                        </div>
                        <div class="comment-info clearfix">
                          <strong>Steven Rich</strong>
                          <div class="comment-time">
                            15 january 2019 At 10:30 pm
                          </div>
                        </div>
                        <div class="text">
                          To take a trivial example, which ever undertakes
                          laborious physical exercise, except obtain some
                          advantage.
                        </div>
                        <Link to="" class="theme-btn reply-btn">
                          Reply
                        </Link>
                      </div>
                    </div>

                    <div class="comment-box reply-comment">
                      <div class="comment">
                        <div class="author-thumb">
                          <img
                            src="../../assets/Images/resource/author-2.jpg"
                            alt=""
                          />
                        </div>
                        <div class="comment-info clearfix">
                          <strong>William Cobus</strong>
                          <div class="comment-time">
                            15 january 2019 At 10:30 pm
                          </div>
                        </div>
                        <div class="text">
                          To take a trivial example, which ever undertakes
                          laborious physical exercise, except obtain some
                          advantage.
                        </div>
                        <Link to="" class="theme-btn reply-btn">
                          Reply
                        </Link>
                      </div>
                    </div>

                    <div class="comment-box">
                      <div class="comment">
                        <div class="author-thumb">
                          <img
                            src="../../assets/Images/resource/author-3.jpg"
                            alt=""
                          />
                        </div>
                        <div class="comment-info clearfix">
                          <strong>Van Wimbilton</strong>
                          <div class="comment-time">
                            15 january 2019 At 10:30 pm
                          </div>
                        </div>
                        <div class="text">
                          To take a trivial example, which ever undertakes
                          laborious physical exercise, except obtain some
                          advantage.
                        </div>
                        <Link to=" " lass="theme-btn reply-btn">
                          Reply
                        </Link>
                      </div>
                    </div>
                  </div> */}

                  {/* <!-- Comment Form --> */}
                  {/* <div class="comment-form">
                    <div class="group-title">
                      <h3>Leave A Comment</h3>
                    </div>

                    {/* <!--Comment Form--> 
                    <form
                      method="post"
                      action="https://expert-themes.com/html/globex/blog.html"
                    >
                      <div class="row clearfix">
                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                          <input
                            type="text"
                            name="username"
                            placeholder="Full Name"
                            required=""
                          />
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            required=""
                          />
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            required=""
                          />
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea
                            class="darma"
                            name="message"
                            placeholder="Your Message"
                          ></textarea>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <button
                            class="theme-btn btn-style-three"
                            type="submit"
                            name="submit-form"
                          >
                            <span class="txt">Post Comments</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Sidebar Page Container --> */}

        {/* <!-- News More Section --> */}
        {/* <section class="news-more-section">
          <div class="auto-container">
            <div class="row clearfix">
              {/* <!-- New Post --> 
              <div class="new-post col-lg-6 col-md-12 col-sm-12">
                <div class="post-inner">
                  <div class="content">
                    <div class="post-image">
                      <img
                        src="../../assets/Images/resource/news-21.jpg"
                        alt=""
                      />
                    </div>
                    <h4>
                      <Link to="#">Define World Best IT Solution </Link>
                    </h4>
                    {/* <Link to="#" class="more">Previous</Link> 
                  </div>
                </div>
              </div>
              {/* <!-- New Post --> 
              <div class="new-post style-two col-lg-6 col-md-12 col-sm-12">
                <div class="post-inner">
                  <div class="content">
                    <div class="post-image">
                      <img
                        src="../../assets/Images/resource/news-22.jpg"
                        alt=""
                      />
                    </div>
                    <h4>
                      <Link to="#">You Must Try 20 Secret Of Digital</Link>
                    </h4>
                    {/* <Link to="#" class="more">Next</Link> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- End News More Section --> */}

        {/* <!-- Info Section --> */}
        {/* <section
          class="info-section"
          style={{
            backgroundImage: "url(../../assets/Images/background/6.jpg)",
          }}
        >
          <div class="auto-container">
            <div class="row clearfix">
              <div class="logo-column col-lg-3 col-md-6 col-sm-12">
                <div class="inner-column">
                  <div class="logo">
                    <Link to="">
                      <img src="../../assets/Images/logo-2.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>

              <div class="info-column col-lg-3 col-md-6 col-sm-12">
                <div class="inner-column">
                  <div class="icon-box">
                    <span class="flaticon-pin"></span>
                  </div>
                  <ul>
                    <li>
                      <strong>Address</strong>
                    </li>
                    <li>
                      #58-12-13/1, 2nd Floor, Sanjeevanagar, Rajahmundry-533105
                    </li>
                  </ul>
                </div>
              </div>

              <div class="info-column col-lg-3 col-md-6 col-sm-12">
                <div class="inner-column">
                  <div class="icon-box">
                    <span class="flaticon-phone-call"></span>
                  </div>
                  <ul>
                    <li>
                      <strong>Phone</strong>
                    </li>
                    <li>+91 9100625636</li>
                  </ul>
                </div>
              </div>

              <div class="info-column col-lg-3 col-md-6 col-sm-12">
                <div class="inner-column">
                  <div class="icon-box">
                    <span class="flaticon-email-1"></span>
                  </div>
                  <ul>
                    <li>
                      <strong>E-Mail</strong>
                    </li>
                    <li>info@vincosys.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- End Info Section --> */}
      </div>
    </div>
  );
};

export default Blog4;
