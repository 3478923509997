import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
import "../../assets/css/style2.css";
import { Link } from "react-router-dom";
import { services } from "../../utils/environments/api";

const Header = () => {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Home");

  const isActive = (paths) => paths.includes(location.pathname);
  const handleLinkClick = (list) => {
    setActiveLink(list);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (e) => {
    // Close the dropdown if clicked outside of the button
    if (!e.target.closest(".dropdown")) {
      setIsOpen(false);
    }
  };

  // Listen for clicks outside the dropdown to close it
  React.useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  // Handle scroll event to toggle sticky header
    useEffect(() => {
  const handleScroll = () => {
    // Calculate total header height
    const mainHeader = document.querySelector(".main-header");
    const mainHeaderHeight = mainHeader ? mainHeader.offsetHeight : 0;

    // Check scroll position
    const offset = window.scrollY;
    setIsSticky(offset > mainHeaderHeight);
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);


  const [service, setService] = useState([]);

  useEffect(() => {
    serviceData();
  }, []);

  const serviceData = async () => {
    try {
      const res = await services.get("get_service_typ_api.php");
      if (res.data.data) {
        setService(res.data.data);
      } else {
        console.error("Invalid response format:", res);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  // Function to hide mobile menu
  const hideMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div>
      <header className="main-header header-style-one">
        <div className="header-top">
          <div className="auto-container">
            <div className="clearfix">
              <div className="top-left">
                <ul className="info-list">
                  <li>
                    <Link to="">
                      <span className="icon">
                        <i className="ri-mail-line"></i>
                      </span>{" "}
                      info@vincosys.com
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <span className="icon">
                        <i className="ri-phone-line"></i>
                      </span>
                      9100625636
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="top-right pull-right">
                <ul className="social-box">
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/vincosys/"
                      target="_blank"
                    >
                      <i class="ri-linkedin-fill"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/vincosys/profilecard/?igsh=dTFnaG1nNGpjamxm"
                      target="_blank"
                    >
                      <i class="ri-instagram-line"></i>
                    </Link>
                  </li>
                  {/* <li>
                        <Link to="">
                          <i class="ri-dribbble-fill"></i>
                        </Link>
                      </li> */}
                  <li>
                    <a
                      href="https://wa.me/919100625636?text=Hi%20there%2C%20I%20would%20like%20to%20contact%20you!"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ri-whatsapp-line"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {!isSticky && (
          <div className="header-upper">
            <div className="auto-container clearfix">
              <div className="pull-left logo-box">
                <div className="logo">
                  <img
                    src="../../assets/Images/vincosys.png"
                    alt="Vincosys"
                    title=""
                  />
                </div>
              </div>

              <div className="nav-outer clearfix">
                <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                  <span className="icon">
                    <i className="ri-menu-3-fill"></i>
                  </span>
                </div>

                {/* Main Menu with Close Button */}
                <nav
                  className={`main-menu navbar-expand-md ${
                    isMobileMenuOpen ? "mobile-menu-open" : ""
                  }`}
                >
                  {isMobileMenuOpen && (
                    <button
                      className="close-mobile-menu"
                      onClick={toggleMobileMenu}
                    >
                      <i className="ri-close-line"></i>
                    </button>
                  )}
                  <div
                    className="navbar-collapse collapse clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix clearfix2 mobile">
                      <li
                        className={`current ${isActive(["/"]) ? "active" : ""}`}
                      >
                        <Link to="/" onClick={hideMobileMenu}>
                          Home
                        </Link>
                      </li>
                      <li
                        className={`current ${
                          isActive(["/AboutUs"]) ? "active" : ""
                        }`}
                      >
                        <Link to="/AboutUs" onClick={hideMobileMenu}>
                          About
                        </Link>
                      </li>
                      <li
                        className={`current dropdown srv-drpdwn ${
                          isActive(["/Service", "/Service1"]) ? "active" : ""
                        }`}
                      >
                        <Link to="#" onClick={hideMobileMenu}>
                          Services
                        </Link>
                        <ul>
                          {service.map((item) => (
                            <li key={item.servicetype_id}>
                              <Link
                                to={`/Service?id=${item.servicetype_id}`}
                                onClick={hideMobileMenu}
                              >
                                {item.service}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li
                        className={`dropdown current service-dropdown ${
                          isActive(["/Service", "/Service1"]) ? "active" : ""
                        }}`}
                      >
                        <Link to="#" onClick={toggleDropdown}>
                          Services
                        </Link>
                        {isOpen && (
                          <ul>
                            {service.map((item) => (
                              <li key={item.servicetype_id}>
                                <Link
                                  to={`/Service?id=${item.servicetype_id}`}
                                  onClick={hideMobileMenu}
                                >
                                  {item.service}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                      <li
                        className={`current ${
                          isActive(["/Products", "/ProductSinglePage"])
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link to="/Products" onClick={hideMobileMenu}>
                          Products
                        </Link>
                      </li>
                      <li
                        className={`current ${
                          isActive([
                            "/Careers",
                            "/ServiceIot",
                            "/RandDService",
                            "/ProductDev",
                            "/WebDev",
                            "/MobileDev",
                            "/DigitalMarketing",
                          ])
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link to="/Careers" onClick={hideMobileMenu}>
                          Careers
                        </Link>
                      </li>
                      <li
                        className={`contact ${
                          isActive(["/ContactUs"]) ? "active" : ""
                        }`}
                      >
                        <Link to="/ContactUs" onClick={hideMobileMenu}>
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>

                <div className="outer-box clearfix">
                  <div className="btn-box">
                    <Link to="/ContactUs" className="theme-btn btn-style-one">
                      <span className="txt">Contact Us</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>

      <div  
        className={`sticky-header sticky-active ${isSticky ? "sticky" : ""}`}
      >
        <div className="auto-container clearfix">
          <div className="pull-left logo-box">
            <div className="logo">
              <img src="../../assets/Images/vincosys.png" alt="Vincosys" />
            </div>
          </div>

          <div className="nav-outer clearfix">
            <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
              <span className="icon">
                <i className="ri-menu-3-fill"></i>
              </span>
            </div>

            <nav
              className={`main-menus navbar-expand-md ${
                isMobileMenuOpen ? "mobile-menu-open" : ""
              }`}
            >
              {isMobileMenuOpen && (
                <button
                  className="close-mobile-menu"
                  onClick={toggleMobileMenu}
                >
                  <i className="ri-close-line"></i>
                </button>
              )}
              <div
                className="navbar-collapse collapse clearfix"
                id="navbarSupportedContent"
              >
                <ul className="navigation clearfix mobile">
                  <li className={`current ${isActive(["/"]) ? "active" : ""}`}>
                    <Link to="/" onClick={hideMobileMenu}>
                      Home
                    </Link>
                  </li>
                  <li
                    className={`current ${
                      isActive(["/AboutUs"]) ? "active" : ""
                    }`}
                  >
                    <Link to="/AboutUs" onClick={hideMobileMenu}>
                      About
                    </Link>
                  </li>
                  <li
                    className={`dropdown current ${
                      isActive(["/Service", "/Service1"]) ? "active" : ""
                    }`}
                  >
                    <Link to="#" onClick={hideMobileMenu}>
                      Services
                    </Link>
                    <ul>
                      {service.map((item) => (
                        <li key={item.servicetype_id}>
                          <Link
                            to={`/Service?id=${item.servicetype_id}`}
                            onClick={toggleDropdown}
                          >
                            {item.service}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li
                    className={`current ${
                      isActive(["/Products", "/ProductSinglePage"])
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="/Products" onClick={hideMobileMenu}>
                      Products
                    </Link>
                  </li>
                  <li
                    className={`current ${
                      isActive([
                        "/Careers",
                        "/ServiceIot",
                        "/RandDService",
                        "/ProductDev",
                        "/WebDev",
                        "/MobileDev",
                        "/DigitalMarketing",
                      ])
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="/Careers" onClick={hideMobileMenu}>
                      Careers
                    </Link>
                  </li>
                  <li
                    className={`current contact ${
                      isActive(["/ContactUs"]) ? "active" : ""
                    }`}
                  >
                    <Link to="/ContactUs" onClick={hideMobileMenu}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>

            <div className="outer-box clearfix">
              <div className="btn-box">
                <Link to="/ContactUs" className="theme-btn btn-style-one">
                  <span className="txt">Contact Us</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
