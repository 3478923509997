import React from 'react'
import About from './About';
import Feature from './Feature';
import Service1 from './Service1';
import Sponsor from './Sponsor';
import Testimonials from './Testimonials';
import Team from './Team';
import Blog from './Blog';
import CalltoAction from './CalltoAction';
import Banner from './Banner';

const Home = () => {
  return (
    <div>
        <Banner/>
          {/* About section */}
          <About />
          <Feature />
          <Service1 />
          <CalltoAction/>
          <Sponsor />
          <Testimonials />
          {/* <Team /> */}
          <Blog/>
    </div>
  )
}

export default Home
